<!--
 * @Description: 订房订单
 * @Author: 琢磨先生
 * @Date: 2022-05-18 11:42:03
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-10-12 16:51:22
-->
<template>
  <el-card class="box">
    <el-tabs v-model="query.select_type" class="demo-tabs" @tab-click="tabClick">
      <el-tab-pane label="全部" :name="0"></el-tab-pane>
      <el-tab-pane label="今日预抵" :name="1"></el-tab-pane>
      <el-tab-pane label="今日预离" :name="2"></el-tab-pane>
      <el-tab-pane label="今日新办" :name="3"></el-tab-pane>
      <el-tab-pane label="待处理" :name="4"></el-tab-pane>
    </el-tabs>
    <el-form :model="query" ref="query" label-width="80px" :inline="true">
      <el-form-item label="关键字">
        <el-row class="q-row">
          <el-col :span="10">
            <el-input v-model="query.q" placeholder="单号、姓名、手机号" style="width: 300px" clearable></el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="房间">
        <!-- <el-row class="q-row">
          <el-col :span="10">
            <el-input v-model="query.house_no" placeholder="" style="width: 200px" clearable></el-input>
          </el-col>
        </el-row>-->
        <el-cascader
          :options="huxing_list"
          v-model="query.storeIds"
          clearable
          :props="{ multiple: true }"
          collapse-tags
          collapse-tags-tooltip
          filterable
        ></el-cascader>
      </el-form-item>
      <el-form-item label="日期选择" v-if="![1, 2, 3].find((o) => o == query.select_type)">
        <el-row class="q-row">
          <el-select v-model="query.date_type" placeholder style="width: 100px; border: none">
            <el-option label="入住日期" :value="0"></el-option>
            <el-option label="离店日期" :value="1"></el-option>
            <el-option label="在店日期" :value="2"></el-option>
          </el-select>
          <el-date-picker
            v-model="query.date_list"
            type="daterange"
            value-format="YYYY-MM-DD"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-row>
      </el-form-item>
      <el-form-item label="渠道">
        <el-select v-model="query.ota_id" placeholder clearable style="width: 150px">
          <el-option :label="item.name" :value="item.id" v-for="item in ota_list" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="入住状态">
        <el-select v-model="query.status_list" multiple placeholder clearable>
          <el-option
            :label="item.text"
            :value="item.value"
            v-for="item in enums.order_status"
            :key="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="结账状态">
        <el-select v-model="query.settle_status" placeholder clearable style="width:150px;">
          <el-option
            :label="item.text"
            :value="item.value"
            v-for="item in enums.order_settle_status"
            :key="item.value"
          ></el-option>
        </el-select>
      </el-form-item>-->
      <el-form-item label="备注">
        <el-input v-model="query.remark" placeholder="操作备注" style="width: 300px" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
        <el-tooltip class="box-item" effect="dark" content="下载查询结果数据" placement="top">
          <el-button
            icon="Download"
            v-power="'seller/v1/houseOrder/export/excel'"
            @click="downloadExcel"
            :loading="downloading"
          >下载数据</el-button>
        </el-tooltip>
      </el-form-item>
    </el-form>
  </el-card>
  <el-card class="box data">
    <el-table v-loading="loading" :data="tableData.data" border @sort-change="onSort">
      <el-table-column label="编号" fixed="left" prop="id" width="80"></el-table-column>
      <el-table-column label="订单号" fixed="left" width="200">
        <template #default="scope">
          <el-link
            type="primary"
            :underline="false"
            v-if="is_can_dtl"
            @click="onDtl(scope.row)"
          >{{ scope.row.order_no }}</el-link>
          <div v-else>{{ scope.row.order_no}}</div>
        </template>
      </el-table-column>
      <el-table-column label="渠道" prop="ota_name" width="140"></el-table-column>
      <el-table-column label="联系人" prop="contact_name" width="140"></el-table-column>
      <el-table-column label="手机号" prop="mobile_phone" width="140"></el-table-column>
      <el-table-column label="房间" min-width="600">
        <template #default="scope">
          <el-row v-for="item in scope.row.order_stores" :key="item.id">
            {{ item.huxing_name }} {{ item.house_no }}、房费￥{{
            item.decimal_amount
            }}、入离：{{item.checkin_date}}~{{item.checkout_date}}、共{{item.days}}晚
          </el-row>
        </template>
      </el-table-column> 
      <el-table-column label="入住状态" prop="status_text" width="120">
        <template #default="scope">
          <el-link
            :underline="false"
            :type="scope.row.status==10?'warning':scope.row.status==15?'primary':scope.row.status==25?'info':'default'"
          >{{ scope.row.status_text }}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="房费" width="120">
        <template #default="scope">￥ {{ scope.row.decimal_amount }}</template>
      </el-table-column>
      <el-table-column label="订单总额" width="120">
        <template #default="scope">￥ {{ scope.row.decimal_total_amount }}</template>
      </el-table-column>
      <el-table-column label="下单时间" prop="create_at" width="180"></el-table-column>
      <el-table-column label="操作备注" prop="operate_remark" min-width="300"></el-table-column>
      <el-table-column label="操作" width="140"></el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData.counts > 0"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
  <order-dtl v-if="current_order_id" :order_id="current_order_id" @close="onClose"></order-dtl>
</template>

<script>
import common_api from "@/http/common_api";
import OrderDtl from "./order_dtl.vue";
import ota_api from "@/http/ota_api";
import fileDownload from "js-file-download";
import huxing_api from "@/http/huxing_api";

export default {
  components: {
    OrderDtl,
  },
  data() {
    return {
      loading: false,
      downloading: false, //下载中
      enums: {},
      //渠道列表
      ota_list: [],
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
        select_type: 0,
        q_type: 0,
        date_type: 0,
      },
      //房号
      storeList: [],
      //数据
      tableData: {
        counts: 0,
      },
      //当前订单id
      current_order_id: null,
      //可以查看详情
      is_can_dtl: false,
      huxing_list: [],
    };
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });

    ota_api.get_otas().then((res) => {
      if (res.code == 0) {
        this.ota_list = res.data;
      }
    });
    huxing_api.get_stores().then((res) => {
      if (res.code == 0) {
        this.huxing_list = [];
        res.data.forEach((x) => {
          var model = {
            value: x.id,
            label: x.name,
            children: [],
          };
          x.store_list.forEach((item) => {
            model.children.push({
              value: item.id,
              label: item.house_no,
            });
          });
          this.huxing_list.push(model);
        });
      }
    });
    this.loadData();

    this.is_can_dtl = this.$power("seller/v1/houseOrder/dtl");
  },
  methods: {
    /**
     * tab点击
     */
    tabClick(tab) {
      if ([1, 2, 3].find((o) => o == tab.props.name)) {
        this.query.date_list = [];
      }
      this.query.select_type = tab.props.name;
      this.onSearch();
    },

    /**
     * 远程搜索房源房号
     * @param {*} q
     */
    remoteStore(q) {
      common_api.get_housestore(q).then((res) => {
        if (res.code == 0) {
          this.storeList = res.data;
        }
      });
    },

    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      var arg = Object.assign({}, this.query);
      delete arg.storeIds;
      if (
        Array.isArray(this.query.storeIds) &&
        this.query.storeIds.length > 0
      ) {
        arg.store_ids = this.query.storeIds.map((x) => {
          return x[1];
        });
      }
      this.$http.post("seller/v1/houseorder", arg).then((res) => {
        this.loading = false;
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 展开订单详情
     * @param {*} item
     */
    onDtl(item) {
      this.current_order_id = item.id;
    },

    /**
     * 详情页面关闭
     */
    onClose() {
      this.current_order_id = null;
      this.loadData();
    },

    /**
     * 下载数据
     */
    downloadExcel() {
      this.downloading = true;
      var arg = Object.assign({}, this.query);
      delete arg.storeIds;
      if (
        Array.isArray(this.query.storeIds) &&
        this.query.storeIds.length > 0
      ) {
        arg.store_ids = this.query.storeIds.map((x) => {
          return x[1];
        });
      }
      this.$http
        .post("seller/v1/houseOrder/export/excel", arg, {
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res, "订单导出.xlsx");
        })
        .finally(() => {
          this.downloading = false;
        });
    },
  },
};
</script>

<style  >
.q-row {
  border: 1px solid var(--el-border-color);
  border-radius: var(--el-input-border-radius, var(--el-border-radius-base));
}
.q-row .el-input__wrapper {
  box-shadow: none;

  /* border:1px solid var(--el-border-color); */
}
.q-row .el-range-editor:hover,
.q-row .el-range-editor {
  box-shadow: none;
}
</style>